import { ChildProps } from '@apollo/client/react/hoc';
// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import { compact } from 'lodash';

import { withGraphql } from '../../with_graphql';
import { EVERYWHERE_CODE } from '../../constants';
import experiments from '../../experiments';
import ListingsCollection from '../../components/listings_collection';
import { MParticleEventName } from '../../elog/mparticle_tracker';
import { isExperimentEnabled } from '../../user_context_helpers';
import SanitizedRender from '../../components/sanitized_render';
import { IDynamicComponentProps } from '../dynamic_component_props';
import { withRouter, WithRouterProps } from 'react-router';
import {
  CommonsCmsListingsCollection,
  reverb_search_ListingsSearchRequest_PriceValue,
  reverb_search_ListingsSearchRequest_Sort,
  reverb_search_ListingsSearchRequest as ListingsSearchRequest,
} from '../../gql/graphql';
import {
  withUserContext,
  IUser,
  IUserContext,
} from '../../components/user_context_provider';
import { useViewTracking } from '../../use_tracking';
import { AdFragment, ImageAdCreativeFragment } from '../../components/ads/ad_fragments';
import { MParticleEvent } from '../../elog/mparticle_types';
import { mParticleListingFields } from '../../components/with_mparticle_listings';
import { userContextIsTrackable } from '../../elog/mparticle';
import {
  localizeListingsRowsFilters,
  showLocalizedListingContent,
} from '../../localize_listing_rows_utils';
import { OverflowingRowAction } from '../../components/overflowing_row_action';
import classNames from 'classnames';
import { BumpDSACompliancePopover } from '../../components/bump_dsa_compliance_popover';
import { MosaicPatternProps, ColSpanProps } from '../../components/mosaic_listings_collection';
import { ListingsCollectionFragments } from '../../components/listings_collection_fragments';

const COMPONENT_NAME = 'CMSListingsCollection';

export interface ListingFields {
  id: string;
}

export enum ADDITIONAL_SORTS {
  BUMP_BOOST = 'BUMP_BOOST',
  RANDOM = 'RANDOM',
}

export type CMSListingsCollectionSorts = reverb_search_ListingsSearchRequest_Sort | ADDITIONAL_SORTS;

export interface ExternalProps extends IDynamicComponentProps {
  acceptsGiftCards?: boolean;
  acceptsOffers?: boolean;
  acceptsPaymentPlans?: boolean;
  brandUuids?: string[];
  brandSlugs?: string[];
  categoryUuids?: string[];
  componentName?: string;
  conditionSlugs?: string[];
  countryOfOrigin?: string;
  cspId?: string;
  ctaTargetHref?: string;
  ctaText?: string;
  curatedSetId?: string;
  currency?: string;
  displayAddToCart?: boolean;
  displayAsGrid?: boolean;
  freeExpeditedShipping?: boolean;
  freeShipping?: boolean;
  handmade?: boolean;
  holidaySale?: boolean;
  itemRegion?: string;
  jumplinkSlug?: string;
  largeTiles?: boolean;
  ledeHtml?: string;
  listings?: ListingFields[];
  maxCount?: number;
  minCount?: number;
  mParticleView?: MParticleEvent;
  onSale?: boolean;
  preferredSeller?: boolean;
  priceMax?: string;
  priceMin?: string;
  productType?: string;
  query?: string;
  saleCuratedSetId?: string;
  saleSlug?: string;
  shopSlugs?: string[];
  sort?: CMSListingsCollectionSorts;
  boostByBumpRate?: boolean;
  subCategory?: string;
  subtitle?: string | React.ReactNode;
  title?: string;
  titleHtml?: string;
  titleTooltip?: React.ReactNode;
  trackingName?: string;
  user?: IUser;
  userShippingRegion?: string;
  yearMax?: string;
  yearMin?: string;
  adKeywords?: string[];
  traits?: string[];
  dealsOrWellPriced?: boolean;
  priceValueV2?: reverb_search_ListingsSearchRequest_PriceValue;
  shipsToMe?: boolean;
  shouldDisplayAsLanding?: boolean;
  similarListingIds?: string[];
  withSidebar?: boolean;
  localPickup?: boolean;
  grayBackground?: boolean;
  indentedMosaic?: boolean;
  excludeShopIds?: string[];
  displayMobileMosaic?: boolean;
  handleCreateWatchResult?: () => void;
  mosaicListingRow?: boolean;
  mosaicPattern?: MosaicPatternProps;
  colCount?: ColSpanProps;
  includeItemListMetadata?: boolean;
  seed?: number;
}

export type IProps = ExternalProps & IUserContext;

function getItemRegionFilters(ownProps): ListingsSearchRequest {
  if (ownProps.itemRegion) {
    return { itemRegion: ownProps.itemRegion };
  }

  /* If an item region is not specifically set, then default to everywhere.
  For most situations, this is a no-op. But if a sticky seller location exists for the user,
  we cancel it out to display more listings. It also prevents confusion
  since an implicit sticky seller location filter on CMS rows is surprising. */
  return { itemRegion: EVERYWHERE_CODE, ...localizeListingsRowsFilters(ownProps.user) };
}

export function getSort(ownProps) {
  if (ownProps.sort === ADDITIONAL_SORTS.BUMP_BOOST) {
    return reverb_search_ListingsSearchRequest_Sort.NONE;
  }
  return ownProps.sort;
}

function buildComponentName(componentName, sort) {
  if (sort === ADDITIONAL_SORTS.BUMP_BOOST) {
    return `Bumped${componentName}`;
  }

  return componentName;
}

function getSubtitle(ownProps) {
  if (ownProps.sort === ADDITIONAL_SORTS.BUMP_BOOST) {
    return <BumpDSACompliancePopover/>;
  }
  return ownProps.subtitle;
}

export function CMSListingsCollection(props: ChildProps<IProps & WithRouterProps, CommonsCmsListingsCollection.Query>) {
  const minCount = props.minCount || 1;
  const componentName = buildComponentName(props.trackingName || props.componentName || COMPONENT_NAME, props.sort);
  const listings = props.data?.listingsSearch?.listings || [];

  const impressionData = {
    eventName: MParticleEventName.ListingImpression,
    listingIds: listings.map(l => l.id),
    listings,
    componentName,
  };

  useViewTracking(impressionData, props.data?.variables?.bumpedOnly && !props.data?.loading);
  const shouldNotRender = !props.data?.loading && listings.length < minCount;

  useViewTracking({
    eventName: MParticleEventName.ComponentView,
    componentName: COMPONENT_NAME,
    cmsComponentId: props.componentId,
    listingsCount: listings.length,
    title: props.title,
  }, !props.data?.loading);

  if (shouldNotRender) {
    return null;
  }

  const classes = classNames(
    { 'bg-offwhite bg--lighten ptb-8 mtb-6': props.grayBackground },
  );

  function callToAction() {
    return (
      <OverflowingRowAction
        href={props.ctaTargetHref}
        text={props.ctaText}
      />
    );
  }

  function getListingCollection() {
    return (
      <ListingsCollection
        callToAction={
          !!props.ctaTargetHref && callToAction()
        }
        mParticleView={props.mParticleView}
        componentName={componentName}
        displayAsGrid={props.displayAsGrid}
        largeTiles={props.largeTiles}
        listings={listings}
        loading={props.data.loading}
        maxCount={props.maxCount}
        title={
          <SanitizedRender
            html={props.titleHtml || props.title}
          />
        }
        titleTooltip={props.titleTooltip}
        titleString={props.title}
        jumplinkSlug={props.jumplinkSlug}
        subtitle={getSubtitle(props)}
        ledeHtml={props.ledeHtml}
        ad={props.data.adServe?.ad}
        showFlags={showLocalizedListingContent(props.user)}
        shouldDisplayAsLanding={props.shouldDisplayAsLanding}
        withSidebar={props.withSidebar}
        handleCreateWatchResult={props.handleCreateWatchResult}
        indentedMosaic={props.indentedMosaic}
        displayMobileMosaic={props.displayMobileMosaic}
        mosaicListingRow={props.mosaicListingRow}
        mosaicPattern={props.mosaicPattern}
        colCount={props.colCount}
        includeItemListMetadata={props.includeItemListMetadata}
        cmsComponentId={props.componentId}
        displayAddToCart={props.displayAddToCart}
      />
    );
  }

  return (
    props.grayBackground ? (
      <div className={classes} >
        {getListingCollection()}
      </div>
    ) : (
      getListingCollection()
    )
  );
}

export const cmsListingsCollectionQuery = gql`
    query Commons_Cms_ListingsCollection(
      $acceptsGiftCards: Boolean
      $acceptsOffers: Boolean
      $acceptsPaymentPlans: Boolean
      $brandUuids: [String]
      $brandSlugs: [String]
      $bumpedOnly: Boolean!
      $boostByBumpRate: Boolean!
      $categorySlugs: [String]
      $categoryUuids: [String]
      $conditionSlugs: [String]
      $countryOfOrigin: [String]
      $cspId: String
      $curatedSetId: String
      $currency: String
      $freeExpeditedShipping: Boolean
      $freeShipping: Boolean
      $handmade: Boolean
      $holidaySale: Boolean
      $itemRegion: String
      $itemRegionRelation: reverb_search_ListingItemRegionRelation
      $limit: Int
      $listingIds: [String]
      $onSale: Boolean
      $preferredSeller: Boolean
      $priceMax: String
      $priceMin: String
      $query: String
      $saleCuratedSetId: String
      $saleSlugs: [String]
      $shippingRegionCodes: [String]
      $shopSlugs: [String]
      $sort: reverb_search_ListingsSearchRequest_Sort
      $yearMax: String
      $yearMin: String
      $showListingLocation: Boolean!
      $adKeywords: [String]
      $hasAdKeywords: Boolean!
      $traitSlugs: [String]
      $dealsOrWellPriced: Boolean
      $priceValueV2: reverb_search_ListingsSearchRequest_PriceValue
      $shipsToMe: Boolean
      $shouldSkipTracking: Boolean!
      $similarListingIds: [String]
      $localPickup: Boolean
      $excludeShopIds: [String]
      $randomSeed: Int
    ) {
      listingsSearch(
        input: {
          acceptsGiftCards: $acceptsGiftCards
          acceptsOffers: $acceptsOffers
          acceptsPaymentPlans: $acceptsPaymentPlans
          brandUuids: $brandUuids
          brandSlugs: $brandSlugs
          bumpedOnly: $bumpedOnly
          boostByBumpRate: $boostByBumpRate
          categorySlugs: $categorySlugs
          categoryUuids: $categoryUuids
          conditionSlugs: $conditionSlugs
          countryOfOrigin: $countryOfOrigin
          cspId: $cspId
          curatedSetId: $curatedSetId
          currency: $currency
          freeExpeditedShipping: $freeExpeditedShipping
          freeShipping: $freeShipping
          handmade: $handmade
          holidaySale: $holidaySale
          ids: $listingIds
          itemRegion: $itemRegion
          itemRegionRelation: $itemRegionRelation
          limit: $limit
          onSale: $onSale
          preferredSeller: $preferredSeller
          priceMax: $priceMax
          priceMin: $priceMin
          query: $query
          saleCuratedSetId: $saleCuratedSetId
          saleSlugs: $saleSlugs
          shippingRegionCodes: $shippingRegionCodes
          shopSlugs: $shopSlugs
          sort: $sort
          traitSlugs: $traitSlugs
          yearMax: $yearMax
          yearMin: $yearMin
          statuses: ["live"],
          dealsOrWellPriced: $dealsOrWellPriced
          priceValueV2: $priceValueV2
          shipsToMe: $shipsToMe
          similarListingIds: $similarListingIds
          localPickup: $localPickup
          excludeShopIds: $excludeShopIds
          randomSeed: $randomSeed
        }
      ) {
        listings {
          _id
          ... ListingsCollection
          ... mParticleListingFields @skip(if: $shouldSkipTracking)
          ... BumpKey @include(if: $bumpedOnly)
          ... ShopFields @include(if: $showListingLocation)
        }
      }
      adServe(input: {
        targeting: {
          keywords: $adKeywords
        }
      }) @include (if: $hasAdKeywords) {
        ad {
          ...AdData
          imageAdCreative {
            ...ImageAdCreativeData
          }
        }
      }
    }
    ${mParticleListingFields}
    ${ListingsCollectionFragments.listing}
    ${ListingsCollectionFragments.bumpKey}
    ${ListingsCollectionFragments.shopFields}
    ${AdFragment}
    ${ImageAdCreativeFragment}
  `;

export function buildCmsListingsCollectionVars(ownProps: IProps & WithRouterProps) {
  return {
    ssr: ownProps.ssr,
    variables: {
      acceptsGiftCards: ownProps.acceptsGiftCards,
      acceptsOffers: ownProps.acceptsOffers,
      acceptsPaymentPlans: ownProps.acceptsPaymentPlans,
      brandUuids: ownProps.brandUuids,
      brandSlugs: ownProps.brandSlugs,
      bumpedOnly: ownProps.sort === ADDITIONAL_SORTS.BUMP_BOOST,
      boostByBumpRate: ownProps.sort === ADDITIONAL_SORTS.BUMP_BOOST,
      categorySlugs: compact([
        ownProps.productType,
        ownProps.subCategory,
      ]),
      categoryUuids: ownProps.categoryUuids,
      conditionSlugs: ownProps.conditionSlugs,
      cspId: ownProps.cspId,
      countryOfOrigin: compact([ownProps.countryOfOrigin]),
      curatedSetId: ownProps.curatedSetId,
      currency: ownProps.currency,
      freeExpeditedShipping: ownProps.freeExpeditedShipping,
      freeShipping: ownProps.freeShipping,
      handmade: ownProps.handmade,
      holidaySale: ownProps.holidaySale,
      ...getItemRegionFilters(ownProps),
      limit: ownProps.maxCount,
      listingIds: (ownProps.listings || []).map(l => l.id),
      onSale: ownProps.onSale,
      preferredSeller: ownProps.preferredSeller,
      priceMax: ownProps.priceMax,
      priceMin: ownProps.priceMin,
      query: ownProps.query,
      saleCuratedSetId: ownProps.saleCuratedSetId,
      saleSlugs: ownProps.saleSlug && [ownProps.saleSlug],
      shippingRegionCodes: [ownProps.userShippingRegion || ownProps.user?.shippingRegionCode],
      shopSlugs: ownProps.shopSlugs,
      showListingLocation: isExperimentEnabled(ownProps.user, experiments.LISTING_CARD_LOCATION),
      sort: getSort(ownProps),
      yearMax: ownProps.yearMax,
      yearMin: ownProps.yearMin,
      adKeywords: ownProps.adKeywords,
      hasAdKeywords: !!(ownProps.adKeywords && ownProps.adKeywords.length > 0),
      traitSlugs: ownProps.traits,
      dealsOrWellPriced: ownProps.dealsOrWellPriced,
      priceValueV2: ownProps.priceValueV2,
      shipsToMe: ownProps.shipsToMe,
      shouldSkipTracking: !userContextIsTrackable(ownProps.user),
      similarListingIds: ownProps.similarListingIds,
      localPickup: ownProps.localPickup,
      excludeShopIds: ownProps.excludeShopIds,
      randomSeed: ownProps.seed,
    },
  };
}

const query = withGraphql<IProps & WithRouterProps, CommonsCmsListingsCollection.Query>(
  cmsListingsCollectionQuery,
  {
    options: buildCmsListingsCollectionVars,
  },
);

export default withUserContext<IProps>(withRouter(query(CMSListingsCollection)));

